/* You can add global styles to this file, and also import other style files */
@import '@angular/material/theming';
@import '@covalent/core/theming/all-theme';

// (optional) Additional themes
//@import '~@covalent/markdown/markdown-theme';
//@import '~@covalent/highlight/highlight-theme';

@include mat-core();

$primary: mat-palette($mat-light-blue, 500);
$accent: mat-palette($mat-blue-grey, 700, 800, 900);

$warn: mat-palette($mat-red, 600);

$accept: mat-palette($mat-green, 600);
$decline: mat-palette($mat-red, 600);

$theme: mat-light-theme($primary, $accent, $warn);

@include angular-material-theme($theme);
@include covalent-theme($theme);

// (optional) Additional themes
//@include covalent-markdown-theme($theme);
//@include covalent-highlight-theme();

/**
 * Builds specific CSS rules
 */
.mat-content {
  min-height: 100%;
}

.mat-icon.md-icon-logo {
  width: 180px;
}

.blurText {
  filter: blur(3px);
  -webkit-filter: blur(3px);
}

$background-primary: #232323;
$background-accent: #353535;
$background-warn: #c18e0c;
$font-color-default: silver;

$green: #55BE00;
$red: #FF0000;
$blue: #0081ff;

@mixin snack-theme($theme) {
  .mat-snack-bar-container {
    color: $font-color-default;
  }
  //Added with panelClass property
  .snack-error {
    background-color: $red;
    color:#fff;
    button {
      color:#fff;
    }
  }
  .snack-warn {
    background-color: $background-warn;
    color:#fff;
    button {
      color:#fff;
    }
  }
  //Added with panelClass property
  .snack-success {
    background-color: $green;
    color:#fff;
    button {
      color:#fff;
    }
  }
}

@include snack-theme($theme);

