@import '~../node_modules/quill/dist/quill.snow.css';

$mat-font-url: './';

$green: #55BE00;
$red: #FF0000;
$blue: #0081ff;

.hasAction {
  .intercom-app div,.intercom-lightweight-app div {
    right: 90px !important;
  }
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v140/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

/* fallback */
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v109/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2) format('woff2');
}

* {
  font-family: "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif !important;
}

.material-icons {
  font-family: "Material Icons" !important;
}

mat-dialog-container {
  position: relative;
}

/**
 * Globals
 */
.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-none {
  float: none;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.bold {
  font-weight: bold;
}

/**
 * Other styles
 */
.top-logo {
  max-width: 200px;

  .mat-icon.mat-icon-logo {
    width: 40px;
    height: 40px;
    max-width: 200px;
  }
}
.cdk-global-overlay-wrapper {
  overflow-x: auto;
}
.cdk-overlay-backdrop{
 top: 64px !important;
}
agm-map {
  height: 500px;
}

ngx-charts-advanced-pie-chart > div {
  margin: 20px 0;
}

.ride-months-graph {
  overflow: hidden;
  clear: both;
  height: 400px;
  margin: 40px 30px 30px 20px;
}

.driver-score {
  mat-icon {
    margin: 5px auto;
  }
}

mat-card-title.filter {
  button.tool {
    float: right;
    margin: -4px 0 0 0 !important;
  }
}

.small-title {
  font-size: 18px;
  margin-bottom: 0px;
}

.filter-fields {
  .td-search-input-field {
    margin-top: 3px;
  }

  .mat-form-field-type-mat-select {
    .mat-form-field-infix {
      margin-top: 3px;
    }
  }
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  line-height: 20px;
}

.searchDriver {
  td-search-input {
    .mat-form-field-infix {
      @media(max-width: 960px) {
        left: 6px;
        position: relative;
      }
    }

    input.mat-input-element {
      @media(max-width: 960px) {
        left: 39px;
        position: relative;
      }
    }
  }
}

.date-range-picker {
  overflow: hidden;

  p.label {
    margin: 24px 0 0 0;
    padding: 0 15px;
    float: left;
    @media(max-width: 600px) {
      min-width: 55px;
      padding: 0 5px 0 15px;
    }
  }

  mat-form-field {
    float: left;
    @media(max-width: 600px) {
      width: calc(100% - 95px);
      float: none;
    }

    > .mat-input-wrapper {
      margin-top: 4px;
    }

    &:last-of-type {
      @media(min-width: 600px) {
        margin-right: 30px;
      }
    }
  }
}

.max-width-300 {
  max-width: 300px;

  .wrap-line {
    width: 280px;
  }
}

.max-width-350 {
  max-width: 350px;

  .wrap-line {
    width: 330px;
  }
}

.max-width-400 {
  max-width: 400px;

  .wrap-line {
    width: 380px;
  }
}

.wrap-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.operator-rating-first-field {
  margin-top: 35px;
}

.value-container {
  &.first {
    margin-top: 0;
  }

  .label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.38);
    margin-bottom: 0;
  }

  .value {
    font-size: 16px;
    color: #000;
    margin-top: 0;
  }
}

/**
 * Material overrides
 */
.mat-tab-label {
  mat-icon {
    vertical-align: middle
  }
}

.user-avatar {
  img {
    width: 60px !important;
    height: 60px !important;
  }
}

.details-card, .job-details-card {
  overflow: hidden;

  .mat-list .mat-list-item .mat-line,
  .mat-nav-list .mat-list-item .mat-line {
    white-space: normal;
  }

  .mat-list .mat-list-item.value .mat-line:nth-child(n+2),
  .mat-nav-list .mat-list-item.value .mat-line:nth-child(n+2) {
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
  }

  .mat-list .mat-list-item.value .mat-line:nth-child(1),
  .mat-nav-list .mat-list-item.value .mat-line:nth-child(1) {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.38);
  }
}

.job-details-card,
.assign-pane,
.assign-pane .mat-tab-body-wrapper,
.assign-pane .mat-tab-body-wrapper .mat-tab-body-content {
  overflow: visible !important;
}

.assign-pane .mat-tab-body {
  overflow-y: visible !important;
  display: inline-table;
}

.assign-pane .mat-tab-body-wrapper .mat-tab-body-content {
  display: block;
  width: 100%;
}

.mat-nav-list .mat-list-item.value,
.mat-list .mat-list-item.value {
  .mat-list-item-content {
    align-items: baseline;
    padding-top: 0 !important;
  }
}

mat-tab-body {
  td-search-input {
    width: 100%;
  }
}

.ride-log-entry {
  pre {
    margin: 4px 0;
  }
}

mat-card {
  &.clickable {
    &:hover {
      background: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }
  }

  mat-card-content.widget-filter {
    padding: 16px 16px 0;
    margin-bottom: 0;

    span.label {
      display: inline-block;
      margin-right: 20px;
    }
  }
}

mat-divider[matInset] {
  width: calc(100% - (72px + 16px)) !important;
}

.clickable {
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome and Opera */
}

.tab-content {
  padding: 16px;
}

.full-width {
  width: 100%;
}

.field-description {
  opacity: 0.7;
  font-size: 14px;
  margin-top: 5px;
}

.mat-list {
  .mat-list-item.value {
    &.small {
      height: 45px;
    }
  }
}

.ride-filter {
  .date-range-picker {
    p.label {
      margin: 24px 0 0 0;
      padding: 0 0 0 15px;
      float: left;
    }

    mat-form-field {
      width: 60%;
      float: left;

      > .mat-input-wrapper {
        margin-top: 4px;
      }

      &:last-of-type {
        margin-right: 30px;
      }
    }
  }
}

.label-select {
  padding: 0 15px;
}

.zEWidget-launcher {
  bottom: 45px !important;
}

#launcher {
  bottom: 40px !important;
}

.pad-bottom-for-zendesk {
  padding-bottom: 75px;
}

.custom-side-menu {
  padding-bottom: 100px;
}

.positive {
  color: $green !important;
}

.negative {
  color: $red !important;
}

.disabled {
  color: #8c8c8c !important;
  filter: grayscale(1);
}

.maxSize1490 {
  @media(max-width: 1490px) {
    display: none;
  }
}

.maxSize1440 {
  @media(max-width: 1440px) {
    display: none;
  }
}

.maxSize1280 {
  @media(max-width: 1280px) {
    display: none;
  }
}

.maxSize1180 {
  @media(max-width: 1180px) {
    display: none;
  }
}

.maxSize1024 {
  @media(max-width: 1024px) {
    display: none;
  }
}

.maxSize950 {
  @media(max-width: 950px) {
    display: none;
  }
}

.maxSize900 {
  @media(max-width: 900px) {
    display: none;
  }
}

.maxSize600 {
  @media(max-width: 600px) {
    display: none;
  }
}

.minSize150 {
  min-width: 150px;
}

.minSize175 {
  min-width: 175px;
}

.minSize250 {
  min-width: 250px;
}

@media(max-width: 1024px) {
  .w-15 {
    width: 15px;
  }
  .w-30 {
    width: 30px;
  }
  .w-75 {
    width: 75px;
  }
  .w-80 {
    width: 80px;
  }
  .w-100 {
    width: 100px;
  }
  .w-120 {
    width: 120px;
  }
  .w-135 {
    width: 135px;
  }
  .w-150 {
    width: 150px;
  }
  .w-200 {
    width: 200px;
  }
  .w-216 {
    width: 216px;
  }
}

@media(min-width: 1024px) {
  .w-15 {
    width: 15px;
  }
  .w-30 {
    width: 30px;
  }
  .w-75 {
    width: 75px;
  }
  .w-80 {
    width: 80px;
  }
}
.max-w-100 {
  max-width: 100px;
}
.max-w-300 .wrap-line {
  max-width: 300px;
}
.max-w-100p  {
  width:100%;
  .wrap-line {
    max-width: 100%;
  }
}

.max-w-700 {
  max-width: 700px;
}

span.td-data-table-heading {
  @media(min-width: 960px) {
    padding: 0 14px 0 28px !important;
  }
  @media(max-width: 600px) {
    padding: 0 14px 0 14px !important;
  }
}

tbody.empty td{
  padding: 50px;
  width: 460px;
  margin: auto;
  padding-bottom: 50px;
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
}

.yda-link {
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
}

.yda-link:hover {
  color: #03a9f4;
}

.yda-link-blue {
  color: #03a9f4;
  cursor: pointer;
}

.yda-link-blue:hover {
  color: rgba(0, 0, 0, 0.87);
}

.pointer {
  cursor: pointer;
}

.warning span {
  vertical-align: super;
  font-size: 12px;
}

.phoneNumberField .warning span {
  line-height: 20px;
}

.warning .mat-icon {
  font-size: 18px !important;
  margin-right: 5px;
}

.mat-form-field-prefix {
  padding-right: 5px;
}

.phoneNumberInvalid {
  ngx-intl-tel-input {
    .phoneNumberInput {
      color: #e53935 !important;
      border-bottom: 1px solid #e53935 !important;
      font-size: 16px;
    }

    .iti__flag-container {
      border-bottom: 1px solid #e53935 !important;
    }
  }

  .phoneNumberInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: red;
    opacity: 1; /* Firefox */
    font-size: 16px;
  }

  .phoneNumberInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: red;
    font-size: 16px;
  }

  .phoneNumberInput::-ms-input-placeholder { /* Microsoft Edge */
    color: red;
    font-size: 16px;
  }

  .phonenumber-warning {
    float: left;
    width: 100%;
  }
}

.phoneNumberInputDisabledBorder {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
  background-position: bottom;
  position: relative;
  height: 1px;
}

ngx-intl-tel-input {
  .dropdown-toggle {
    padding-left: 0px;
  }

  .phoneNumberInput {
    border: 0;
    border-top: 0.45375em solid transparent;
    border-bottom: 1px solid rgb(148, 148, 144);
    padding-top: 9px;
    line-height: 28px;
    width: calc(100% - 37px);
    padding-left: 0 !important;
    margin-left: 46px !important;
    font-size: 16px;
    min-width: 178px;
    border-radius: 0px;
    @media(max-width: 960px) {
      width: calc(100% - 42px);
    }
    @media(max-width: 960px) {
      padding-top: 0px;
    }
  }

  .phoneNumberInput:focus-visible {
    outline: none;
  }

  .phoneNumberInputDisabled {
    background-color: #ffffff;
    border-bottom: none;
  }

  .iti {
    width: calc(100% - 10px);
    filter: grayscale(0) !important;
  }

  .iti__flag-container {
    border-bottom: 1px solid rgb(148, 148, 144);
    top: 14px !important;
    filter: grayscale(0) !important;
    @media(max-width: 960px) {
      top: 5px !important;
    }
  }

  .disabled {
    background-color: #ffffff;
    border-bottom: none;
  }
}

.mat-drawer-inner-container {
  overflow: visible !important;
}

.assign-pane .hiddenOverflow .mat-tab-body-wrapper {
  overflow: hidden !important;
}

.mat-list-base {
  padding-top: 0 !important;
}

.table-placeholder {
  color: #bebebe;
  font-size: 20px;
  text-align: center;
  padding: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-clickable .td-data-table-cell-content-wrapper,
.mat-clickable .td-data-table-heading {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.checkbox-td .td-data-table-cell-content-wrapper {
  padding-right: 0px !important;
}

button.accept-button {
  background-color: #4caf50;
  color: #ffffff;
}

.back-button {
  mat-icon {
    display: block;
    float: left;
    margin-top: 8px;
  }
}

.mat-tooltip {
  font-size: 14px;
  line-height: 20px;
}

td-search-box.filter-search button {
  @media (max-width: 600px) {
    padding: 0 15px;
    width: 63px;
  }
}

.dialog-pane {
  position: relative;
}

.dialog-close svg {
  transition: .3s all;
  vertical-align: middle;
}

.dialog-close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -0.25rem;
  right: 0.25rem;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid #000000;
  border-radius: 50%;
  cursor: pointer;
  transition: .3s transform, .3s border;
}
.support-pane {
  border:1px solid #ff9959;
  background-color: #f8efe8;
  padding:16px;
  display:inline-block;
}
.input-warning {
  border: 1px solid red !important;
}
.input-warning-text{
  mat-label {
    color: red;
  }
}

.icon-right {
  margin-right: 6px;
}
td-layout-card-over {
  background-color: #e0e0e0 !important;
  background-image: url('/assets/img/bg_chat.png') !important;
}
/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #03a9f4;
  color: #03a9f4;
  box-shadow: 9999px 0 0 -5px #03a9f4;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: .25s;
}

.dot-pulse::before, .dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #03a9f4;
  color: #03a9f4;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #03a9f4;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #03a9f4;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: .5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #03a9f4;
  }
  30% {
    box-shadow: 9984px 0 0 2px #03a9f4;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #03a9f4;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #03a9f4;
  }
  30% {
    box-shadow: 9999px 0 0 2px #03a9f4;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #03a9f4;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #03a9f4;
  }
  30% {
    box-shadow: 10014px 0 0 2px #03a9f4;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #03a9f4;
  }
}

.protected_account {
  margin-left: 10px;
  font-style: italic;
  color: #727272;
}

.credit, .red {
  color: $red;
}

.debit, .green {
  color: $green;
}

.blue {
  color: $blue;
}

.custom-header-row .mat-checkbox-background {
  background-color: #ffffff;
}

.max-200 {
  max-width: 200px;
}

.max-400 {
  max-width: 400px;
}

.dialog-top-right {
  right: 10px;
  top: 10px;
  cursor: pointer;
  position: absolute;
}

.dialog-top-right:hover {
  color: #818181;
}

.mat-snack-bar-container {
  white-space: pre-wrap
}

.no-dialog-padding .mat-dialog-container {
  padding: 10px;
}

.zero-dialog-padding .mat-dialog-container {
  padding: 0px;
}

::ng-deep .cdk-overlay-container, .cdk-global-overlay-wrapper {
  white-space: pre-line;
}

.mat-button {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  font-size: 12px;

  &.link-button {
    border: 1px solid #ffffff !important;
    text-decoration: underline;

    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.12) !important;
    }
  }
}

.push-right-small {
  margin-right: 8px !important;
}

.mobile-row {
  @media(max-width: 940px) {
    margin-top: 12px !important;
  }
}
.flow-root{
  display: flow-root !important;
}
.action-pane,.action-pane-max-width {
  margin: 0 auto !important;
  display: flow-root !important;

  button {
    float: right;
    margin-left: 8px;
  }

  mat-card-actions {
    width: auto;
  }
}
.action-pane-max-width {
  margin: 0 !important;
}

mat-card-actions.dialog-buttons {
  padding-left: 23px;
  display: flow-root;
  margin-bottom: -20px;

  button {
    float: right;
  }
}
mat-card-actions {
  display: flow-root !important;
  margin-bottom: 0 !important;
  button {
    float: right;
    &:first-of-type {
      margin-right:0 !important;
    }
  }
}

.row-container {
  .row-layout, .mobile-row {
    display: flow-root;
  }

  mat-list-item {
    display: inline-block !important;
  }
}

.form-left {
  margin-right: 4%;
}

.phonenumner_placeholder {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  color: rgba(0, 0, 0, 0.54);
  top: 1.28125em;
  position: absolute;
  left: -9px;
  width: 200px;
  z-index: 1;
}

app-webbooker-integration-code {

  mat-expansion-panel {
    box-shadow: none !important;;
    margin: 0 !important;

    .mat-expansion-panel-body {
      padding: 0 !important;
    }
  }
}

.color-picker {
  .selected-color-background, .selected-color {
    width: 25px !important;
    height: 25px !important;
    top: 0 !important;
  }

  .left, .right {
    padding: 0 8px !important;
  }

  .hue {
    margin-bottom: 4px !important;
  }

  .hue-alpha {
    .ng-star-inserted {
      height: 8px !important;
    }
  }
}

.left_form {
  padding-right: 15px;
}

.warning-pane{
  padding:20px;
  font-size:16px;
  background-color: #F8F4D5;
  border: #e9dd7e 2px solid;
  color: #96722E;
  margin:20px;
  border-radius: 6px;
  mat-icon {
    margin-right:5px;
    vertical-align: text-top;
  }
}

.hidden-visibility {
  visibility: hidden;
  height:0px;
}
.input-help {
  float:right;
}
